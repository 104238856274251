import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { round } from 'lodash';
import { map } from 'rxjs';
import { CompanyType } from 'src/app/core/enums/auth/company-type';
import { NegotiationStatus } from 'src/app/core/enums/orders/negotiation-status';
import { FormHelper } from 'src/app/core/helpers/form-helper';
import { Negotiation } from 'src/app/core/interfaces/orders/negotiation';
import { Order } from 'src/app/core/interfaces/orders/order';
import { NegotiationsService } from 'src/app/core/services/api/negotiations.service';
import { ToastService } from 'src/app/core/services/utilities/toast.service';
import { selectUser } from 'src/app/core/state/auth/auth.selectors';

export type ExecutionDataForm = ReturnType<
  ReturnType<OrderNegotiationComponent['buildExecutionDataForm']>['getRawValue']
>;

enum NegotiationView {
  OFFERS,
  COUNTER_OFFER,
  EXECUTION_DATA,
  SUMMARY,
}

@Component({
  selector: 'app-order-negotiation',
  templateUrl: './order-negotiation.component.html',
  styleUrl: './order-negotiation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderNegotiationComponent implements OnInit {
  protected readonly NegotiationView = NegotiationView;
  protected readonly NegotiationStatus = NegotiationStatus;
  protected readonly CompanyType = CompanyType;

  public order = input.required<Order>();
  public negotiation = input.required<Negotiation>();

  protected companyType = toSignal(
    this.store.pipe(
      select(selectUser),
      map((user) => user?.company?.type),
    ),
  );
  protected view = signal(NegotiationView.OFFERS);
  protected loading = signal(false);

  protected totalDistance = computed(
    () => (this.order().distance + (this.order().emptyDistance ?? 0)) / 1000,
  );

  // Offers

  protected showMessage = computed(() => {
    const status = this.negotiation().status;
    switch (this.companyType()) {
      case CompanyType.CARRIER:
        return [
          NegotiationStatus.COUNTER_OFFER,
          NegotiationStatus.CARRIER_ACCEPTED,
        ].includes(status);
      case CompanyType.SENDER:
        return [
          NegotiationStatus.FINAL_OFFER,
          NegotiationStatus.SENDER_ACCEPTED,
        ].includes(status);
      default:
        return true;
    }
  });
  protected counterOfferDisabled = computed(() => {
    const status = this.negotiation().status;
    switch (this.companyType()) {
      case CompanyType.CARRIER:
        return status !== NegotiationStatus.INITIAL_OFFER;
      case CompanyType.SENDER:
        return status !== NegotiationStatus.COUNTER_OFFER;
      default:
        return true;
    }
  });
  protected acceptDisabled = computed(() => {
    const status = this.negotiation().status;
    switch (this.companyType()) {
      case CompanyType.CARRIER:
        return (
          status === NegotiationStatus.INITIAL_OFFER &&
          !this.negotiation().initialOfferPrice
        );
      case CompanyType.SENDER:
        return ![
          NegotiationStatus.COUNTER_OFFER,
          NegotiationStatus.CARRIER_ACCEPTED,
        ].includes(status);
      default:
        return true;
    }
  });

  // Counter offer

  protected counterOfferForm = this.buildCounterOfferForm();
  protected counterOfferBasePrices = computed(() => {
    const price =
      this.negotiation().carrierCounterOffer?.price ??
      this.negotiation().initialOfferPrice;
    if (!price) {
      return {
        price: null,
        pricePerKilometer: null,
      };
    }

    return {
      price,
      pricePerKilometer: round(price / this.totalDistance(), 2),
    };
  });

  // Execution data

  protected executionDataForm = this.buildExecutionDataForm();

  // Summary

  protected finalPrice = computed(
    () =>
      this.negotiation().senderFinalOffer?.price ??
      this.negotiation().carrierCounterOffer?.price ??
      this.negotiation().initialOfferPrice,
  );

  constructor(
    private store: Store,
    private negotiationsService: NegotiationsService,
    private toastService: ToastService,
    private fb: NonNullableFormBuilder,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    effect(
      () => {
        if (
          this.companyType() === CompanyType.CARRIER &&
          this.negotiation().status === NegotiationStatus.SENDER_ACCEPTED
        ) {
          this.executionDataForm.controls.vehiclePlateNumber.setValue(
            this.order().vehicle!.id,
          );
          this.view.set(NegotiationView.EXECUTION_DATA);
        } else if (this.negotiation().status === NegotiationStatus.COMPLETED) {
          this.view.set(NegotiationView.SUMMARY);
        }
      },
      { allowSignalWrites: true, injector: this.injector },
    );
  }

  // Offers

  protected showTerms(): void {
    window.open(`/assets/onyx-global/${this.order().companyId}.pdf`, '_blank');
  }

  protected reject(): void {
    this.loading.set(true);
    this.negotiationsService
      .rejectNegotiation(this.order().uuid)
      .subscribe({
        next: () =>
          this.toastService.showSuccess(
            'Zlecenie odrzucone',
            'Akcja zakończona powodzeniem',
          ),
        error: () =>
          this.toastService.showError(
            'Wysyłanie nie powiodło się',
            'Spróbuj jeszcze raz',
          ),
      })
      .add(() => this.loading.set(false));
  }

  protected counterOffer(): void {
    this.counterOfferForm = this.buildCounterOfferForm();

    const priceControl = this.counterOfferForm.controls.price;
    const basePrices = this.counterOfferBasePrices();

    if (this.companyType() === CompanyType.CARRIER) {
      priceControl.addValidators(
        Validators.min(basePrices.price ?? Number.EPSILON),
      );
    } else if (basePrices.price) {
      priceControl.addValidators(Validators.max(basePrices.price));
    }

    this.view.set(NegotiationView.COUNTER_OFFER);
  }

  protected accept(): void {
    this.loading.set(true);
    this.negotiationsService
      .acceptNegotiation(this.order().uuid)
      .subscribe({
        next: () =>
          this.toastService.showSuccess(
            'Zlecenie zaakceptowane',
            'Akcja zakończona powodzeniem',
          ),
        error: () =>
          this.toastService.showError(
            'Wysyłanie nie powiodło się',
            'Spróbuj jeszcze raz',
          ),
      })
      .add(() => this.loading.set(false));
  }

  // Counter offer

  protected updateCounterOfferPricePerKilometer(price: number | null): void {
    this.counterOfferForm.controls.pricePerKilometer.setValue(
      price ? round(price / this.totalDistance(), 2) : null,
      { emitEvent: false },
    );
  }

  protected updateCounterOfferPrice(pricePerKilometer: number | null): void {
    this.counterOfferForm.controls.price.setValue(
      pricePerKilometer
        ? round(pricePerKilometer * this.totalDistance(), 2)
        : null,
      { emitEvent: false },
    );
  }

  protected cancelCounterOffer(): void {
    this.view.set(NegotiationView.OFFERS);
  }

  protected submitCounterOffer(): void {
    if (this.counterOfferForm.invalid) {
      FormHelper.showAllErrors(this.counterOfferForm);
      return this.toastService.showError(
        'Wysyłanie nie powiodło się',
        'Popraw dane',
      );
    }

    this.loading.set(true);
    this.negotiationsService
      .counterOfferNegotiation(
        this.order().uuid,
        this.counterOfferForm.value.price!,
      )
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            'Kontroferta wysłana',
            'Akcja zakończona powodzeniem',
          );
          this.view.set(NegotiationView.OFFERS);
        },
        error: () =>
          this.toastService.showError(
            'Wysyłanie nie powiodło się',
            'Spróbuj jeszcze raz',
          ),
      })
      .add(() => this.loading.set(false));
  }

  protected buildCounterOfferForm() {
    return this.fb.group({
      price: this.fb.control<number | null>(null, [Validators.required]),
      pricePerKilometer: this.fb.control<number | null>(null, [
        Validators.required,
      ]),
    });
  }

  // Execution data

  protected submitExecutionData(): void {
    if (this.executionDataForm.invalid) {
      FormHelper.showAllErrors(this.executionDataForm);
      return this.toastService.showError(
        'Wysyłanie nie powiodło się',
        'Popraw dane',
      );
    }

    this.loading.set(true);
    this.negotiationsService
      .completeNegotiation(
        this.order().uuid,
        this.executionDataForm.getRawValue(),
      )
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            'Dane wykonania zlecenia przesłane',
            'Akcja zakończona powodzeniem',
          );
          this.view.set(NegotiationView.SUMMARY);
        },
        error: () =>
          this.toastService.showError(
            'Wysyłanie nie powiodło się',
            'Spróbuj jeszcze raz',
          ),
      })
      .add(() => this.loading.set(false));
  }

  protected buildExecutionDataForm() {
    return this.fb.group({
      driver: this.fb.group({
        firstName: this.fb.control('', [Validators.required]),
        lastName: this.fb.control('', [Validators.required]),
        phoneNumber: this.fb.control('', [
          Validators.required,
          Validators.pattern(/^\+(?:[0-9]){6,14}[0-9]$/),
        ]),
      }),
      vehiclePlateNumber: this.fb.control(
        {
          value: '',
          disabled: true,
        },
        [Validators.required],
      ),
      semiTrailerPlateNumber: this.fb.control('', [Validators.required]),
    });
  }
}
